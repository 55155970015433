import React, { useState } from 'react'
import { Button, Col, Container, Jumbotron, Row } from 'reactstrap'
import style from './style/home-style.css'
import homeImage from './images/homepage_background.jpg'
import { useNavigate } from "react-router-dom";

const backgroundStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  minHeight: '100vh',
  backgroundImage: `url(${homeImage})`
}

function Home () {

  const [currentAccount, setCurrentAccount] = useState(['0x'])
  const [loaded, setLoaded] = useState(false)
  const navigate = useNavigate()

  const connectToMetamask = async () => {
    const ethereum = window.ethereum
    if (!ethereum) {
      alert('Metamask is not installed in your browser!')
    } else {
      console.log('Metamask is installed!')
      await ethereum.request({
        method: 'eth_requestAccounts',
      }).then(handleCurrentAccount)
        .then(navigate("/mint"))

      ethereum.on('accountsChanged', handleCurrentAccount)
    }
  }

  const handleCurrentAccount = (accounts) => {
    setCurrentAccount(accounts[0])
    localStorage.setItem('account', accounts[0])
    localStorage.setItem('networkID', window.ethereum.networkVersion)
  }

  return (
    <div style={backgroundStyle}>
      <Jumbotron fluid >
        <Container fluid >
          <Row>
            <Col>
              <h4>site1</h4>
            </Col>
            <Col>
              <h4>site2</h4>
            </Col>
            <Col>
              <h4>site3</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 2, offset: 5 }}>
              <h5>Connect to enter the cave</h5>
              <Button color="primary" onClick={connectToMetamask}>Connect to Metamask</Button>
            </Col>
          </Row>
          <Row>
            <Col >
              <h3>DAN</h3>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  )
}

export default Home

import React, { useEffect, useState } from 'react'
import { CardHeader, Button, Card, Col, Container, Jumbotron, Row } from 'reactstrap'
import style from './style/home-style.css'
import mintImage from './images/mint_background.jpg'
import web3, { bearApesContractAddress } from './web3'
import { useNavigate } from "react-router-dom";

const abi = require('./abi/BearApes.json').abi

const backgroundStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  minHeight: '100vh',
  backgroundImage: `url(${mintImage})`
}

function Mint () {

  const [currentAccount, setCurrentAccount] = useState(['0x'])
  const [loaded, setLoaded] = useState(false)
  const [totalSupply, setTotalSupply] = useState(0)
  const [mintAction, setMintAction] = useState({ "description": 0, "startingPrice": 0,
    "startTime": 0,"endTime": 0, "ethGasPrice": 0});
  let [mintCount, setMintCount] = useState(100)

  const [price]=useState(0.0069)
  const [amountToMint]=useState(1)
  const [netID]=useState(localStorage.getItem('networkID'))
  const navigate = useNavigate()

  useEffect(() => {
    loadComponent();
  }, []);

  const loadComponent = () => {
    if(Number(netID) !== 4) {
      alert('Please switch to the Ethereum Main Network')
      navigate("/")
    }
    getTotalSupply();
    setEligibility();
    setAccount();
  }

  const setAccount = () => {
    setCurrentAccount(localStorage.getItem('account'))
  }

  const getTotalSupply = () => {
    const bearApeContract = new web3.eth.Contract(abi, bearApesContractAddress)
    bearApeContract.methods.totalSupply().call().then((supply) => {
      setTotalSupply(supply)
      localStorage.setItem('supply', supply)
    }).catch((err) => {
      alert(err)
    })
  }

  const setEligibility = () => {
    const bearApeContract = new web3.eth.Contract(abi, bearApesContractAddress)
    const account = localStorage.getItem('account')
    bearApeContract.methods.mintCount(account).call().then((count) => {
      setMintCount(count);
      localStorage.setItem('mintCount', count)
    }).catch((err) => {
      alert(err)
    })
  }

  const mint = async () => {
    const bearApesContract = new web3.eth.Contract(abi, bearApesContractAddress);
    let paymentMultiplication = amountToMint;
    if(Number(mintCount) === 0) {
      paymentMultiplication--
    }
    let value = (price * paymentMultiplication).toString();
    let valueEther = web3.utils.toWei(value, 'ether')
    let valueEtherHex = web3.utils.toHex(valueEther)
    bearApesContract.methods.mint(amountToMint).send({
      value: valueEtherHex,
      from: currentAccount
    }).on('error', (e) => {
      alert(e.message)
      navigate("/")
    }).on('transactionHash', (txHash) => {
      console.log(txHash);
    }).then((result) => {
      console.log(result);
      alert(result.transactionHash)
    }).catch((e) => {
      console.log(e);
      navigate("/")
    });

  };

  return (
    <div style={backgroundStyle}>
      <Jumbotron fluid >
        <Container fluid >
          <Row>
            <Col>
              <h4>site1</h4>
            </Col>
            <Col>
              <h4>site2</h4>
            </Col>
            <Col>
              <h4>site3</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 2, offset: 5 }}>
              <Button color="secondary" onClick={mint}>Mint</Button>
            </Col>
          </Row>
          <Row>
            <Col >
              <h3>DAN</h3>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  )
}

export default Mint

import React from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Mint from './mint'
import Home from "./home";

function App() {

    return (
        <div>
            <Router>
                <div>
                    <Routes>
                        <Route
                            exact
                            path='/'
                            element={<Home/>}
                        />

                        <Route
                            exact
                            path='/mint'
                            element={<Mint/>}
                        />


                    </Routes>
                </div>
            </Router>

        </div>

    )

}

export default App;
